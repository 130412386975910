html {
  background-color: #202020;

}

/* Scrollbar width */
::-webkit-scrollbar {
  width: 10px;
  /* Adjust the width of the scrollbar */
}

/* Scrollbar handle */
::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar handle */
  border-radius: 10px;
  /* Rounded edges for the handle */
}

/* Scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Darker color on hover */
}