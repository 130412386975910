.main-container {
  display: flex;
  background-image: url('../../img/contactus.png');
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  background-repeat: no-repeat;

  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  object-fit: cover;
}

.background-image {
  z-index: 0;
  height: 100vh;
  width: 100%;
}

.content-box {
  backdrop-filter: blur(2px);
  padding: 5%;
}


.overlay-content {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.divleft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5%;
  border-radius: 2%;
}

.divright {
  width: 80%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 2%;
}

.form-box {
  height: auto;
  padding: 5%;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .divleft {
    margin-top: 5%;
  }

  .divright {
    margin-top: 5%;
  }
}