.clippedDiv {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 55vh;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%);
    clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%); 
}

.clippedDiv::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.3; /* Adjust the opacity as needed */
    z-index: 1; /* Make sure it's above the content */
}


