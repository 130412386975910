.welcomeimg{
    width: 200px;
    height: 200px;
  }
.list-item-container{
   display: 'flex';
    align-items: 'center';
     width: 100% ;
}.ant-card-head-title {
    color: blue;
  }
  .heading-h1{
    color:white;
    font-weight: 500;
  }
  .Dashboard-four-buttons{
    background:"red";
    text-align:"center";
  }
  .buttons-text{
    color: white;
  }
  .buttonContainer{
    padding:5%;
    border-radius: 5%;
}
.text-color{
  color:white
}
.MuiLinearProgress-barColorPrimary{
  background: yellow;
}
.MuiOutlinedInput-root{
  color: white !important;
}
.MuiFormLabel-root.Mui-focused{
  color: #c4b205 !important
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #c4b205 !important;
  border-width: 2px;
}