
.nine h1 {
    text-align:center; font-size:50px; text-transform:uppercase; color:#ffffff; letter-spacing:1px;
    font-family:"Playfair Display", serif; font-weight:400;
    
}
  .nine h1 span {
    margin-top: 5px;
      font-size:15px; color:#c4b205; word-spacing:1px; font-weight:normal; letter-spacing:2px;
      text-transform: uppercase; font-family:"Raleway", sans-serif; font-weight:500;
  
      display: grid;
      grid-template-columns: 1fr max-content 1fr;
      grid-template-rows: 27px 0;
      grid-gap: 20px;
      align-items: center;
  }
  
  .nine h1 span:after,.nine h1 span:before {
      content: " ";
      display: block;
      border-bottom: 1px solid #ffffff;
      border-top: 1px solid #c4b205;
      height: 5px;
    background-color:transparent;
  }

  @media  (max-width: 767px) {
    .nine h1 {
     font-size:30px;
        
    }
}
.animated-container{
    justify-content: center;
}