
.heading {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 40px;
    color: #080808;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  .heading span {
    display: block;
    font-size: 0.5em;
    line-height: 1.3;
  }
  .heading em {
    font-style: normal;
    font-weight: 600;
  }
.six .heading {
    color:#222; font-size:30px; font-weight:700;text-shadow: 1px 1px 2px rgb(117, 117, 117);
  
    text-transform: uppercase;
    word-spacing: 1px;color:#c4b205;
  }
  .six .heading span {
    line-height:2em; padding-bottom:15px;
    text-transform: none;
    font-size:.6em;
    font-weight: normal; font-family: "Playfair Display","Bookman",serif;
    color:#999; letter-spacing:-0.005em; word-spacing:1px;
    letter-spacing:none;
  }
  .six .heading:after, .six .heading:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 4px;
    content: "";
    margin:auto;
    background-color: #ccc;
  }
  .six .heading:before { 
  background-color:#bdb14c;
    width:100%;
  }
  .ourwork{
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 30px;
  }