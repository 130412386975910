.containere::-webkit-scrollbar {
  display: none;
}

.containere {
  display: flex;
  overflow-x: scroll;
  width: 100%;
  padding: 20px 0;
  padding-bottom: 0;
}

.carde {
  border-radius: 0;
  flex-shrink: 0;
  width: 300px;
  height: 400px;
  margin-left: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  /* Add this line to make sure pseudo-element is positioned relative to .card */
  transition: background-color 0.3s;
  /* Add a smooth transition for background-color change */
  margin-top: 20px;
}

.carde::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #c4b205, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));

  opacity: 0;
  pointer-events: none;
  /* Ensure pseudo-element doesn't interfere with hover state */
  transition: opacity 0.3s;
  /* Add a smooth transition for opacity change */
}

.carde:hover {
  background-color: #c4b205
    /* Adjust the opacity as needed */
}

.carde:hover::before {
  opacity: 1;
}

.recent-containere {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5%;
}

.recente {
  display: flex;
  align-items: center;
}

.other-containere {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5%;
}

.othere {
  display: flex;
  align-items: center;

}

.subheadere {
  border-radius: 10px;
}

@media (max-width: 767px) {
  .carde {
    width: 300px;
    height: 300px;
    border-radius: 10px;
    margin-left: 10px;
    margin-top: 10px;
  }

  .carde::before {
    border-radius: 10px;
  }

  .subheadere {
    padding-left: 15%;

  }
}