.container::-webkit-scrollbar {
  display: none;
}

.container {
  display: flex;
  overflow-x: scroll;
  width: 90%;
  padding: 20px 0;
  margin-left: 5%;
}

.card {
  flex-shrink: 0;
  width: 300px;
  height: 200px;
  border-radius: 2%;
  margin-left: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.card::before {
  content: "";
  position: absolute;
  top: 0;
  border-radius: 2%;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #c4b205, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));

  opacity: 0;
  pointer-events: none;
  /* Ensure pseudo-element doesn't interfere with hover state */
  transition: opacity 0.3s;
  /* Add a smooth transition for opacity change */
}

.card:hover {
  background-color: #c4b205
    /* Adjust the opacity as needed */
}

.card:hover::before {
  opacity: 1;
}

.arrowforward {
  color: white;
  position: absolute;
  top: 100px;
  padding: 2%;
  background: rgb(105, 105, 105);
  border-radius: 50%;
  cursor: pointer;
}

.recent-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5%;
}

.recent {
  display: flex;
  align-items: center;
}

.other-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5%;
}

.other {
  display: flex;
  align-items: center;

}

.capitalize-first-letter {
  text-transform: lowercase;
}

.capitalize-first-letter:first-letter {
  text-transform: uppercase;
}