.carousel .thumb {
  height: 80px !important;
  object-fit: cover;
}
  .btne{
    backdrop-filter: blur(15px);
    text-decoration: none;
    border: 2px solid #333;
    letter-spacing: 2px;
    width: 100%;
    transition: all .35s;
    background: grey;
  }
  
  .btne span{
    position: relative;
    z-index: 2;
    color: white;
   
  }
  
  .btne:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: #bbae3a;
    transition: all .85s;
  }
  
  .btne:hover{
    color: #fff;
  }
  
  .btne:hover:after{
    width: 100%;
  }.img-list div {
    cursor: pointer;
    position: relative; /* Required for absolute positioning of pseudo-elements */
  }
  
  /* Image Placeholder Styles */
  .img-list .img-placeholder:before,
  .img-list .img-placeholder:after {
    padding-top: 33.33%;
    content: "";
    display: block;
  }
  
  .img-list .img-placeholder {
    background: #222;
    box-shadow: 0 0 0 8px #222 inset, 0 0 0 9px #333 inset;
    color: #444;
    line-height: 1.2; /* Adjust line height for better spacing */
    text-align: center;
    display: block;
    overflow: hidden; /* To hide the hover-text initially */
  }
  
  .img-list .img-placeholder img {
    max-width: 100%;
    height:250px;
  }
  
  /* The hover effect */
  .img-list div:before {
    transition: all 0.5s ease;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #333;
    transform: scale(0);
  }
  
  .img-list div:hover:before {
    opacity: 0.5;
    transform: scale(1);
  }
  
  .img-list div:after {
    transition: all 0.6s ease 0.2s;
    content: "";
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    border: 1px solid #aaa;
    background: #000;
    opacity: 0;
    transform: scale(0);
  }
  
  .img-list div:hover:after {
    opacity: 0.35;
    transform: scale(1);
  }
  
  /* Hover Text Styles */
  .img-list .hover-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 16px;
    opacity: 0;
    pointer-events: none;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 6; /* Number of lines to show */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
  .title {
    
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 1.5; /* Adjust this value as needed */
    color: white;
    text-align: center;
    padding: 3%;
  }
  
  .img-list div:hover .hover-text {
    opacity: 1;
  }
  @media screen and (max-width: 786px) {
      
  .img-list .img-placeholder img {
    height:200px;
  }

  }
  .card-price {
    display: inline-block;
    width: auto;
    height: 38px;
    background-color: #c4b205;
    -webkit-border-radius: 3px 4px 4px 3px;
    -moz-border-radius: 3px 4px 4px 3px;
    border-radius: 3px 4px 4px 3px;
    border-left: 1px solid #c4b205;
    z-index: 3;
  
    position: relative;
    color: white;
    font-weight: 300;
    font-size: 22px;
    line-height: 38px;
    padding: 0 10px 0 10px;
  }
  
  /* Makes the triangle */
  .card-price:before {
    content: "";
    position: absolute;
    display: block;
    left: -19px;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-right: 19px solid #c4b205;
  }
  
  /* Makes the circle */
  .card-price:after {
    content: "";
    background-color: white;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    display: block;
    position: absolute;
    left: -9px;
    top: 17px;
  }
  
